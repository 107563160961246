import SoftInputIconBoxRoot from "components/SoftInput/SoftInputIconBoxRoot";
import SoftInputIconRoot from "components/SoftInput/SoftInputIconRoot";
// Custom styles for SoftInput
import SoftInputRoot from "components/SoftInput/SoftInputRoot";
import SoftInputWithIconRoot from "components/SoftInput/SoftInputWithIconRoot";
import SoftPhoneInputRoot from "components/SoftInput/SoftPhoneInputRoot";
// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "@/utils/SoftUIContext";
import { InputBaseProps } from "@mui/material";
import { FC, forwardRef } from "react";

interface Props extends Omit<InputBaseProps, "size"> {
  size?: "small" | "medium" | "large";
  icon?: {
    component: React.ReactNode;
    direction: "none" | "left" | "right";
  };
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  phone?: boolean;
  [key: string]: any;
}

export type Ref = any;

// @ts-expect-error
const SoftInput: FC<Props> = forwardRef<Ref, Props>(({ size, icon, error, success, disabled, phone, ...rest }, ref) => {
  let template;
  const [controller] = useSoftUIController();
  const { direction } = controller;

  if (icon) {
    const iconDirection = icon.direction;
    if (icon.component && icon.direction === "left") {
      template = (
        <SoftInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
          {/* @ts-expect-error */}
          <SoftInputIconBoxRoot ownerState={{ size }} onClick={() => rest.onClick && rest.onClick()}>
            <SoftInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
          <SoftInputRoot {...rest} ownerState={{ size, error, success, iconDirection, direction, disabled }} />
        </SoftInputWithIconRoot>
      );
    } else if (icon.component && icon.direction === "right") {
      template = (
        <SoftInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
          <SoftInputRoot {...rest} ownerState={{ size, error, success, iconDirection, direction, disabled }} />
          {/* @ts-expect-error */}
          <SoftInputIconBoxRoot ownerState={{ size }} onClick={() => rest.onClick && rest.onClick()}>
            <SoftInputIconRoot fontSize="small" ownerState={{ size }}>
              {icon.component}
            </SoftInputIconRoot>
          </SoftInputIconBoxRoot>
        </SoftInputWithIconRoot>
      );
    }
  } else if (phone) {
    template = (
      <SoftPhoneInputRoot {...rest} ref={ref} defaultCountry={"tr"} disableAreaCodes ownerState={{ size, error, success, disabled }} />
    );
  } else {
    template = <SoftInputRoot {...rest} ref={ref} ownerState={{ size, error, success, disabled }} />;
  }

  return template;
});

// Setting default values for the props of SoftInput
SoftInput.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  disabled: false,
};

SoftInput.displayName = "SoftInput";

export default SoftInput;
